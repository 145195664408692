export const PHONE_INVALID_MESSAGES = {
	EMPTY: "Пожалуйста, укажите телефон",
	INVALID_COUNTRY_CODE: "Неверный код страны",
	TOO_SHORT: "Номер слишком короткий",
	TOO_LONG: "Номер слишком длинный",
	IS_POSSIBLE_LOCAL_ONLY: "Адрес не соответствует международному формату",
	INVALID_LENGTH: "Некорректная длина номера",
	UNKNOWN_ERROR: "Введите корректный номер телефона",
	IS_POSSIBLE: "Введите корректный номер телефона",
}

export const NAME_INVALID_MESSAGES = {
	EMPTY: "Пожалуйста, укажите имя",
}

export const EMAIL_INVALID_MESSAGES = {
	EMPTY: "Пожалуйста, укажите почту",
	BAD_FORMAT: "Неверный формат email",
	EMAIL_WAS_USED: "Данный адрес уже используется",
}

export const PROMO_CODE_INVALID_MESSAGES = {
	EMPTY: "Пожалуйста, укажите промокод",
	BAD_VALUE: "Неверный промокод",
}
