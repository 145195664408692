import { checkResponseStatus, NetError } from "./utils"
import config from "../config"

/**
 * Отправка чек-листа готовности заведения на email
 * @param {string} email
 * @returns {Promise<boolean>}
 */
export function requestForCheckList(email) {
	// При каждой отправке данных на сервер также шлём Google Analytics client_ID и ройстат:
	let googleAnalyticsId
	let roistatVisit

	try {
		googleAnalyticsId = window.ga && window.ga.getAll()[0].get("clientId")
		roistatVisit =
			window.roistat && window.roistat.visit ? window.roistat.visit : null
	} catch (e) {
		throw e
	}

	return fetch(
		"/api_controller/?module=user&method=requestStepsForOpenBusiness",
		{
			method: "POST",
			body: JSON.stringify({
				email,
				googleAnalyticsId,
				roistatVisit,
			}),
			headers: {
				Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
			},
		}
	)
		.then(rawResponse => rawResponse.json())
		.then(checkResponseStatus)
		.then(response => response.payload === true)
}

// -----------------------------------запросы к amo-crm-facade-----------------------------------------------
/**
 * Общий метод для отправки заявок в AmoCRM
 * @param {string} url
 * @param {string} leadName
 * @param {object} options - свойства email/phone, statusId, responsibleUserId
 * @returns {Promise<boolean>}
 */
function offerRequestAMOCRM(url, leadName, options) {
	// При каждой отправке данных в AmoCRM также шлём utm-метки, если они есть:
	const utms = localStorage.getItem(config.UTM_MARKS)
		? JSON.parse(localStorage.getItem(config.UTM_MARKS))
		: null

	// Получение первой посещенной страницы
	const urlFirstPage = localStorage.getItem(config.WSC_PAGES) 
		? new URL(JSON.parse(localStorage.getItem(config.WSC_PAGES))[0].url)
		: localStorage.getItem(config.FIRST_PAGE_PATH)
		? new URL(localStorage.getItem(config.FIRST_PAGE_PATH))
		: null

	// Отдаем путь без параметров
	const firstPagePath = urlFirstPage 
		? { firstPagePath : urlFirstPage.origin + urlFirstPage.pathname }
		: null	
		
	// Источник перехода
	const referrer = localStorage.getItem(config.WSC_REFERRER)
		? { referrer : new URL(localStorage.getItem(config.WSC_REFERRER)).hostname }
		: localStorage.getItem(config.REFERRER)
		? { referrer : new URL(localStorage.getItem(config.REFERRER)).hostname }
		: null
	
	// Код потребителя
	const consumerCode = options.phone?.startsWith?.('+77')
		? config.CONSUMER_CODES.KZ2
		: options.phone?.startsWith?.('+375')
		? config.CONSUMER_CODES.BY
		: config.CONSUMER_CODES.MAIN_SITE
	
	// При каждой отправке данных в AmoCRM также шлём Google Analytics client_ID:
	let googleAnalyticsId
	let roistatVisit

	try {
		googleAnalyticsId = window.ga && window.ga.getAll()[0].get("clientId")
		roistatVisit =
			window.roistat && window.roistat.visit ? window.roistat.visit : null
	} catch (e) {
		throw e
	}

	return (
		fetch(url, {
			method: "POST",
			headers: {
				// без этого заголовка не работает (при настройке pre-flight-запроса в сервисах). Возможно,
				// потому что в body передаём json, а это уже сложный cors-запрос
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				consumerCode,
				leadName,
				options: {
					googleAnalyticsId,
					roistatVisit,
					...utms,
					...firstPagePath,
					...referrer,
					...options,
				},
			}),
		})
			// Если сервис amo-crm-facade ответил статусом не в диапазоне 200-299
			// (eсли ошибка возникнет при запросе (failed to fetch), то она отловится как TypeError в CtaAbstractSection):
			.then(rawResponse => {
				if (!rawResponse.ok) {
					throw new NetError(
						`AMO-facade response status: ${rawResponse.status}`
					)
				}
			})
	)
	// .then(checkResponseStatus)
	// .then(response => response.payload === true)
}

/**
 * Создание заявки на приложение WL
 * @returns {Promise<boolean>}
 */
export async function requestForWLApplication({ name, phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA WL landing 'основная форма заявки'",
		{
			phone,
			name,
		}
	)
}

/**
 * Заявка на персональное предложение на странице WL
 * * @param {string} name
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForWLPersonalOffer({ name, phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA WL landing 'Персональное предложение'",
		{
			phone,
			name,
		}
	)
}

/**
 * Заявка на персональное предложение на странице WL
 * * @param {string} name
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForWLDesignOffer({ name, phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA WL landing 'Заказать дизайн'",
		{
			phone,
			name,
		}
	)
}

/**
 * Заявка на персональное предложение на странице WL
 * * @param {string} name
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForPasskitDesignOffer({ name, phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA PASSKIT landing 'Заказать дизайн'",
		{
			phone,
			name,
		}
	)
}

/**
 * Заявка на бесплатную консультацию
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForConsultation(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead", // 'https://putsreq.com/aVBskH42sKWN7H5Rx9mW',//
		"CTA Бесплатная консультация",
		{ phone }
	)
}

/**
 * Заявка на бесплатную консультацию по защите от воровства
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForConsultationStealing(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Консультация Воровство",
		{ phone }
	)
}

/**
 * Заявка на настройку кассового терминала
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForInstallationTerminal({ phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Настройка под ключ",
		{ phone }
	)
}

/**
 * Заявка на сайт витрину
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForWlShop(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Сайт Витрина",
		{ phone }
	)
}

/**
 * Заявка на бесплатную консультацию со страницы сервис
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForConsultationService(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA СМ",
		{ phone }
	)
}

/**
 * Заявка на бесплатную консультацию со страницы сервис
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForConsultationServiceTwoColumnForm({ phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA СМ",
		{ phone }
	)
}

/**
 * Заявка на персональное предложение
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForPersonalOffer(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Комплексная автоматизация",
		{ phone }
	)
}

/**
 * Заявка на тарифный план `Enterprise`
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForEnterpriseTariff(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Enterprise",
		{ phone }
	)
}

/**
 * Заявка на три месяца подписки в подарок
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForWLThreeMonthsGift(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		'CTA WL landing "Три месяца подарок"',
		{ phone }
	)
}

/**
 * Заявка в amoCrm при серверной ошибке
 * @param {'phone' | 'email'} inputType
 * @param {string} value
 */
export function ctaErrorAmoOffer(inputType, value) {
	return fetch("/api_controller/?module=user&method=errorRequest", {
		method: "POST",
		body: JSON.stringify({
			phone: inputType === "phone" ? value : null,
			email: inputType === "email" ? value : null,
		}),
		headers: {
			Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
		},
	})
}

/**
 * Заявка на тариф QRM Pro бесплатно (страница qrm)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForQRMProTariff(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		'CTA QRM landing "Управляйте бизнесом через смартфон"',
		{ phone }
	)
}

/**
 * Как стать партнёром Quick Resto (страница partners)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForHowToBecomePartner(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		'CTA Partners "Стать партнёром Quick Resto"',
		{
			phone,
			statusId: config.STATUS_ID_PARTNERS,
			responsibleUserId: config.RESPONSIBLE_USER_ID_PARTNERS,
		}
	)
}

/**
 * Создание заявки в АмоCRM на отправку расчёта возможного заработка для партнёров на email (страница partners)
 * @param {string} email
 * @returns {Promise<boolean>}
 */
export function requestPartnersRevenue(email) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Сколько вы сможете заработать с Quick Resto?",
		{
			email,
			statusId: config.STATUS_ID_PARTNERS,
			responsibleUserId: config.RESPONSIBLE_USER_ID_PARTNERS,
		}
	)
}

export function subscribeOnActions(mail) {
	return fetch(
		"https://services-api.quickresto.ru/mailchimp-adapter/addListMember",
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				mail,
				mailingListID: config.ACTION_SUBSCRIPTION_FIELD_ID,
			}),
		}
	)
		.then(rawResponse => rawResponse.json())
		.then(checkResponseStatus)
		.then(response => response.payload === true)
}

/**
 * Форма "Как сделать бизнес прозрачным?" (страница /bo)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForBOtransparentBusiness(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Бэк прозрачный бизнес",
		{ phone }
	)
}

/**
 * Форма "Закажите настройку Бэк-офиса под ключ" (страница /bo)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForBOInstallation({ phone }) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"CTA Бэк под ключ",
		{ phone }
	)
}

/**
 * Модальное окно "Оставьте заявку" для скачивания веб-терминала(страница "/")
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForDownloadWebTerminal(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Заявка на скачивание веб-терминала ",
		{
			phone,
			tags: ["веб-терминал"],
		}
	)
}

/**
 * Форма "Если нужно открыть кафе как можно быстрее" (страница /avtomatizaciya-kafe)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenCafeFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация кафе",
		{ phone }
	)
}

/**
 * Форма "Если нужно оптимизировать бизнес-процессы как можно быстрее"
 * (страница /avtomatizaciya-restorana)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenRestFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация ресторана",
		{ phone }
	)
}

/**
 * Форма "Открыть бар быстрее?"
 * (страница /avtomatizaciya-bara)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenBarFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация бара",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть кофейню как можно быстрее"
 * (страница /avtomatizaciya-kofejni)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenCoffeeFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация кофейни",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть фудтрак как можно быстрее"
 * (страница /avtomatizaciya-fudtraka)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenFoodtrackFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация фудтрака",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть кальянную как можно быстрее"
 * (страница /avtomatizaciya-kalyannoj)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenHookahFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация кальянной",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть точку фастфуда как можно быстрее"
 * (страница /avtomatizaciya-fastfood)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenFastfoodFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация фастфуда",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть кулинарию как можно быстрее"
 * (страница /avtomatizaciya-kulinarii/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenKulinariiFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация кулинарии",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть пекарню как можно быстрее"
 * (страница /avtomatizaciya-pekarni/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenBakeryFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация пекарни",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть столовую как можно быстрее"
 * (страница /avtomatizaciya-stolovoy/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenCookeryFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация столовой",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть кондитерскую
 * как можно быстрее"
 * (страница /avtomatizaciya-konditerskoy/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenCandyFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация кондитерской",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть пиццерию как можно быстрее"
 * (страница /avtomatizaciya-piccerii/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenPizzaFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация пиццерии",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть ресторан суши как можно быстрее"
 * (страница /avtomatizaciya-sushi/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenSushiFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация ресторана суши",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть паб как можно быстрее"
 * (страница /avtomatizaciya-paba/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenPubFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация паба",
		{ phone }
	)
}

/**
 * Форма "Если нужно открыть столовую как можно быстрее"
 * (страница /avtomatizaciya-stolovoy/)
 * @param {string} phone
 * @returns {Promise<boolean>}
 */
export function requestForAutoOpenСanteenFaster(phone) {
	return offerRequestAMOCRM(
		"https://services-api.quickresto.ru/amo-crm-facade/lead",
		"Автоматизация столовой",
		{ phone }
	)
}
