import {
	PHONE_INVALID_MESSAGES,
	NAME_INVALID_MESSAGES,
	EMAIL_INVALID_MESSAGES,
	PROMO_CODE_INVALID_MESSAGES,
} from "../pages-data/common/invalidMessages"

import {
	checkEmailUsing,
	checkPromoCodeValidState,
	checkPhoneValidState,
	checkEmailValidFormat,
} from "../apiRequests/validators"

/**
 * @typedef {object} formFieldValidState - Состояние валидации поля формы
 * @property {boolean} valid - Валидно ли поле
 * @property {string} message - Посяняющее сообщение состояния валидности -
 *    принича инвалидности или пояснение валидного значения(бонусы от промокода)
 */

/**
 * Валидация имени - "пустота"
 * @param name
 * @returns {Promise<formFieldValidState>}
 */
export async function getNameValidState(name) {
	return name === ""
		? {
				valid: false,
				message: NAME_INVALID_MESSAGES.EMPTY,
		  }
		: {
				valid: true,
				message: "",
		  }
}

/**
 * Валидация почты - "пустота", "формат", "уникальность"
 * @param {string} email - Электронная почта
 * @param {boolean} needServerUniqueValidate - Нужно ли валидировать почту на уникальность
 * @returns {Promise<formFieldValidState>}
 */
export async function getEmailValidState(
	email,
	needServerUniqueValidate = true
) {
	// Значение почты не задано
	if (!email) {
		return {
			valid: false,
			message: EMAIL_INVALID_MESSAGES.EMPTY,
		}
	}

	// Корректность формата почты
	const emailHasValidFormat = await checkEmailValidFormat(email)

	if (!emailHasValidFormat) {
		return {
			valid: false,
			message: EMAIL_INVALID_MESSAGES.BAD_FORMAT,
		}
	}

	// Если `email` имеет верный формат и его нужно провалидировать на уникальность
	// сделать запрос на сервер для проверки используемости почты
	if (needServerUniqueValidate) {
		const isEmailUsing = await checkEmailUsing(email)

		return isEmailUsing
			? {
					valid: false,
					message: EMAIL_INVALID_MESSAGES.EMAIL_WAS_USED,
			  }
			: {
					valid: true,
					message: "",
			  }
	}

	// Иначе считать email валидным, потому что он валиден по формату
	return {
		valid: true,
		message: "",
	}
}

/**
 * Валидация номера телефона
 * @param {string} phone - Номер телефона
 * @returns {Promise<formFieldValidState>}
 */
export async function getPhoneValidState(phone) {
	const doesPhoneHasLetters = new RegExp(/[a-zA-Zа-яА-Я]+/)

	// 'Пустым' телефоном является либо полное отсутствие введенного номера
	// либо если введен только `+`
	if (!phone || phone === "+") {
		return {
			valid: false,
			message: PHONE_INVALID_MESSAGES.EMPTY,
		}
	}
	// Проверка на наличие букв в телефонном номере
	if (doesPhoneHasLetters.test(phone)) {
		return {
			valid: false,
			message: PHONE_INVALID_MESSAGES.UNKNOWN_ERROR,
		}
	}

	const validateState = await checkPhoneValidState(phone)

	return {
		valid: validateState.isValid,
		message: !validateState.isValid
			? PHONE_INVALID_MESSAGES[validateState.invalidMessage]
			: "",
	}
}

/**
 * Валидация промокода - "пустота", "размер", "действительность"
 * @param {string} promoCode - Электронная почта
 * @returns {Promise<formFieldValidState>}
 */
export async function getPromoCodeValidState(promoCode) {
	// Если промокод отсутствует, или меньше 3 символов, он точно не рабочий
	// и нет смысла валидировать его на сервере.
	if (!promoCode) {
		return {
			valid: false,
			message: PROMO_CODE_INVALID_MESSAGES.EMPTY,
		}
	}
	if (promoCode.length < 3) {
		return {
			valid: false,
			message: PROMO_CODE_INVALID_MESSAGES.BAD_VALUE,
		}
	}

	// Если промокод имеет корректный формат нужно провалидировать его на сервере
	const promoCodeValid = await checkPromoCodeValidState(promoCode)

	return {
		valid: promoCodeValid.ok,
		message: promoCodeValid.ok
			? promoCodeValid.payload[0]
			: promoCodeValid.statusText,
	}
}
