import { checkResponseStatus, NetError } from "./utils"

/**
 * Валидация на корректность `email`
 * @returns {Promise<boolean>}
 */
export function checkEmailValidFormat(email) {
	return fetch("/api_controller/?module=email&method=validate", {
		method: "POST",
		body: JSON.stringify({
			email,
		}),
		headers: {
			Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
		},
	})
		.then(rawResponse => rawResponse.json())
		.then(checkResponseStatus)
		.then(response => response.payload)
}

/**
 * Валидация на используемость `email` в системе
 * @returns {Promise<boolean>}
 */
export function checkEmailUsing(email) {
	return fetch("/api_controller/?module=user&method=checkEmail", {
		method: "POST",
		body: JSON.stringify({
			email,
		}),
		headers: {
			Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
		},
	})
		.then(rawResponse => rawResponse.json())
		.then(checkResponseStatus)
		.then(response => response.payload.count > 0)
}

/**
 * Валидация `promoCode` в системе
 * @returns {Promise<Object>} validState
 * @returns {boolean} validState.ok - Валиден ли промокод
 * @returns {string} validState.statusText - Сообщение о том почему промокод НЕВАЛИДЕН
 * @returns {Array<string>} validState.payload - Сообщение о том, что дает промокод
 *        В массиве всегда 1 элемент.
 */
export function checkPromoCodeValidState(promoCode) {
	return (
		fetch("/api_controller/?module=promocode&method=check", {
			method: "POST",
			body: JSON.stringify({
				code: promoCode,
			}),
			headers: {
				Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
			},
		})
			.then(rawResponse => rawResponse.json())
			.then(checkResponseStatus)
			// Обработка ошибки при парсинге `json`
			.catch(error => {
				throw new NetError(error.message)
			})
	)
}

/**
 * Валидация номера телефона.
 * @param {string} phone
 * @returns {Promise<Object>} validState
 * @returns {boolean} validState.isValid - Валиден ли телефон
 * @returns {string} validState.invalidMessage - Причина невалидности
 */
export function checkPhoneValidState(phone) {
	return fetch("/api_controller/?module=phone&method=validate", {
		method: "POST",
		body: JSON.stringify({
			phone,
		}),
		headers: {
			Authorization: "Basic cXJ0ZXN0bG9naW46MXFhenhzdzI",
		},
	})
		.then(
			rawResponse => rawResponse.json(),
			// если ошибка при запросе (сетевая):
			error => {
				throw new NetError(error.message)
			}
		)
		.then(
			response => {
				if (response.status === 200) {
					return {
						isValid: true,
					}
				}
				// если 400, значит валидация не прошла:
				if (response.status === 400) {
					return {
						isValid: false,
						invalidMessage: response.statusText,
					}
				}
				// Если код ответа не 200 или 400, то кинтуть сетевую ошибку:
				throw new NetError(`Server response status: ${response.status}`)
			},
			// если ошибка при парсинге json (синтаксическая), то скорее всего сервер
			// ответил статусом 200, но прислал НЕ json. В этом случае для пользователя
			// определяем ошибку как СЕТЕВУЮ, но сообщение для Sentry шлём от СИНТАКСИЧЕСКОЙ ошибки:
			syntaxError => {
				throw new NetError(syntaxError.message)
			}
		)
}
